import React from 'react'
import Link from 'next/link'
import va from '@vercel/analytics'
import { Paper, Image } from '@mantine/core'

type adsProps = {
  adDetails?: any
  largeScreen?: boolean
}

export const Advertising = ({ adDetails, largeScreen }: adsProps) => {
  const handleAdTracking = () => {
    va.track(bizName)
  }

  const image = adDetails.image1.responsiveImage
  const bizName = adDetails.name

  return (
    <>
      <Paper
        component={Link}
        href={adDetails.url}
        rel="noopener noreferrer"
        target="_blank"
        radius="md"
        style={{ maxWidth: largeScreen ? '700px' : '400px' }}
        onClick={() => handleAdTracking()}
      >
        <Image src={image.src} alt={image.alt} radius="md" />
      </Paper>
    </>
  )
}
