import React, { useEffect, useState } from 'react'
import Link from 'next/link'

import {
  Image,
  Card,
  Text,
  Group,
  Button,
  Accordion,
  Box,
  ActionIcon,
  Spoiler,
  Container,
} from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import { FaPencilAlt, FaEye } from 'react-icons/fa'
import theme from 'common/theme'
import badgePremium from 'common/assets/images/premium-badge.png'

import { useMediaQuery } from '@mantine/hooks'
import { useUser } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'
import { picUrlHelper } from 'lib/picUrlHelper'
import { locationNameUrlHelper } from 'lib/urlHelpers'
import * as menuButtons from '../../lib/menuButtonsHelper'
import hasRole from 'common/utils/hasRole'

import css from './LocationPages.module.css'
import InfoMenuButtons from 'components/ContactInfo/InfoMenuButtons'
import * as cat from 'common/global/categories'

const fallbackImage =
  'https://www.datocms-assets.com/51196/1630440888-istock-1225284622.jpg'

export default function LocationPagePremiumMobile(props: any) {
  const { locData, locId, dockUserId, userOrListing } = props
  const imageTypes = ['pic1', 'pic2']
  const [images, setImages] = useState<string[]>([])
  const membershipDate = new Date(locData?.dateCreated).getFullYear()
  const isMediumScreen = useMediaQuery('(min-width: 30em)')
  const isLocal = process.env.NODE_ENV
  const { user } = useUser()
  const router = useRouter()
  const isBizOwner = user?.email === locData?.contactEmail
  const isAdmin = hasRole(user, 'admin')

  const contactModal = menuButtons.makeContactModal(locData)
  const mapModal = menuButtons.makeMapModal(locData)
  const hoursModal = menuButtons.makeHoursModal(locData)

  const { nameUrl, cityUrl } =
    locData?.locationName && locData?.city
      ? locationNameUrlHelper(locData.locationName, locData.city)
      : locationNameUrlHelper('', '')

  const handleSubCats = () => {
    let subCategoriesFilter = locData.subCategories
      ? [...locData.subCategories]
      : null

    const subcat: Array<string> = []
    subCategoriesFilter
      ?.slice(0, 5)
      .forEach((c) =>
        subcat.push(cat.getSubCategory(c)?.subCategoryName as string),
      )

    return subcat.length > 0 ? (
      <>
        <Accordion variant="contained" mt="md">
          <Accordion.Item value="customization">
            <Accordion.Control>View Services</Accordion.Control>
            <Accordion.Panel>
              <Text>{subcat.join(', ')}</Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </>
    ) : null
  }

  useEffect(() => {
    const imgArr = imageTypes.map((type: string) => {
      return picUrlHelper(locId, type)
    })
    setImages(imgArr)
  }, [])

  const slides = images.map((image) => (
    <Carousel.Slide key={image}>
      <Image
        src={image}
        alt="Images Of Service Provider"
        height={isMediumScreen ? 500 : 300}
        withPlaceholder
        placeholder={
          <Image
            height={isMediumScreen ? 500 : 300}
            src={fallbackImage}
            alt="placeholder Image"
          />
        }
        style={{ zIndex: 0 }}
      />
    </Carousel.Slide>
  ))
  return (
    <>
      <Container
        p="0.225rem 0.625rem 1.025rem 0.625rem"
        style={{ backgroundColor: theme.colors.brandBlueDark }}
        mb="0"
      >
        <Box pb="0.225rem">
          <Text fw={500} fz="lg" c="white" ta="center">
            {locData.locationName}
          </Text>
        </Box>
        <Card padding="sm" radius="none">
          <Card.Section>
            <Carousel
              withIndicators
              loop
              height={isMediumScreen ? 500 : 300}
              classNames={{
                controls: css.carouselControls,
                indicator: css.carouselIndicator,
              }}
            >
              {slides}
            </Carousel>
          </Card.Section>
        </Card>
      </Container>
      <Container p="0.225rem 0.625rem 0 0.625rem">
        <Card padding="0rem 0.75rem 0.75rem 0.75rem" radius="none">
          {/* start Martin's changes */}
          <Text size="lg" fw={700}>
            Location
          </Text>
          <Group style={{ gap: '0.1rem' }}>
            <Text size="xs">{locData.city},</Text>
            <Text size="xs">{locData.state} - </Text>
            <Text size="xs">{locData.zipCode}</Text>
          </Group>
          <Text size="lg" fw={700}>
            Email
          </Text>
          <Text
            size="xs"
            style={{
              color: theme.colors.brandBlue,
            }}
            component={Link}
            href={`mailto:${locData.email}`}
          >
            {locData.contactEmail}
          </Text>
          <Text size="lg" fw={700}>
            Phone
          </Text>
          <Text
            size="xs"
            component={Link}
            style={{
              color: theme.colors.brandBlue,
            }}
            href={`tel:${locData.contactPhone || locData.phoneNumber}`}
          >
            {locData.contactPhone || locData.phoneNumber}
          </Text>
          {/* end Martin's changes */}
          <Group
            noWrap
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Spoiler
              maxHeight={80}
              showLabel="show more"
              hideLabel="hide"
              style={{ fontSize: '0.7rem' }}
            >
              <Text fz="sm" c="dimmed" mt="sm">
                {locData.description}
              </Text>
              <Text fz="sm">member since: {membershipDate}</Text>
            </Spoiler>
            <Image
              src={badgePremium.src}
              height={30}
              width={84}
              alt="verified badge"
            />
            {isAdmin || isBizOwner ? (
              <ActionIcon
                radius="md"
                variant="filled"
                color="orange"
                onClick={() =>
                  router.push(`/admin/location/${cityUrl}/${nameUrl}/${locId}`)
                }
              >
                <FaPencilAlt />
              </ActionIcon>
            ) : null}
          </Group>
          {handleSubCats()}
        </Card>
      </Container>
      <Container mt="xl" mb="lg">
        <InfoMenuButtons
          locData={locData}
          locId={locId}
          contactModal={contactModal}
          hoursModal={hoursModal}
          mapModal={mapModal}
        />
        {dockUserId ? (
          <Group position="center" mt="lg" mb="lg">
            <Button
              fullWidth
              leftIcon={<FaEye size="1.2rem" />}
              variant="outline"
              color="cyan"
              component={Link}
              rel="noopener noreferrer"
              target="_blank"
              href={
                isLocal === 'development'
                  ? `http://localhost:3000/${userOrListing}/${dockUserId}`
                  : `https://thedock.boaterslist.com/${userOrListing}/${dockUserId}`
              }
            >
              Book A Trip
            </Button>
          </Group>
        ) : null}
      </Container>
    </>
  )
}
