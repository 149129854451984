import React, { useEffect, useState } from 'react'
import Link from 'next/link'

import {
  Image,
  Card,
  Text,
  Group,
  Button,
  Accordion,
  Box,
  Transition,
  ActionIcon,
  Spoiler,
  Container,
} from '@mantine/core'
import { FaPencilAlt, FaEye } from 'react-icons/fa'
import theme from 'common/theme'
import badgeVerified from 'common/assets/images/verified-badge.png'

import * as cat from 'common/global/categories'
import * as menuButtons from '../../lib/menuButtonsHelper'
import { locationNameUrlHelper } from 'lib/urlHelpers'
import hasRole from 'common/utils/hasRole'
import { useUser } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'

import { Advertising } from 'components/Ads/Advertising'
import InfoMenuButtons from 'components/ContactInfo/InfoMenuButtons'

export default function LocationPageMobile(props: any) {
  const { locData, locId, adData, dockUserId, userOrListing } = props
  const membershipDate = new Date(locData?.dateCreated).getFullYear()

  const { user } = useUser()
  const router = useRouter()
  const isBizOwner = user?.email === locData?.contactEmail
  const isAdmin = hasRole(user, 'admin')
  const [position, setPosition] = useState(0)
  const [openedAdvert, setOpenedAdvert] = useState(false)
  const isLocal = process.env.NODE_ENV

  const contactModal = menuButtons.makeContactModal(locData)
  const mapModal = menuButtons.makeMapModal(locData)
  const hoursModal = menuButtons.makeHoursModal(locData)

  useEffect(() => {
    setTimeout(() => {
      setOpenedAdvert(true)
    }, 800)

    let intervalId: any
    let adPosition = 0
    const adLooper = () => {
      setOpenedAdvert(false)
      adPosition = adPosition + 1

      if (adPosition === adData.length) {
        adPosition = 0
        setTimeout(() => {
          setPosition(adPosition)
          setOpenedAdvert(true)
        }, 3000)
      } else {
        setTimeout(() => {
          setPosition(adPosition)
          setOpenedAdvert(true)
        }, 3000)
      }
    }

    intervalId = setInterval(adLooper, 15000)
    return () => clearInterval(intervalId)
  }, [])

  const { nameUrl, cityUrl } =
    locData?.locationName && locData?.city
      ? locationNameUrlHelper(locData.locationName, locData.city)
      : locationNameUrlHelper('', '')

  const handleSubCats = () => {
    let subCategoriesFilter = locData.subCategories
      ? [...locData.subCategories]
      : null

    const subcat: Array<string> = []
    subCategoriesFilter
      ?.slice(0, 5)
      .forEach((c) =>
        subcat.push(cat.getSubCategory(c)?.subCategoryName as string),
      )

    return subcat.length > 0 ? (
      <>
        <Accordion variant="contained" mt="md">
          <Accordion.Item value="customization">
            <Accordion.Control>View Services</Accordion.Control>
            <Accordion.Panel>
              <Text>{subcat.join(', ')}</Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </>
    ) : null
  }
  console.log('DAT', locData)
  return (
    <>
      <Container
        p="sm"
        style={{ backgroundColor: theme.colors.brandBlueDark }}
        mb="0"
      >
        <Box pb="0.225rem">
          <Text fw={500} fz="lg" c="white" ta="center">
            {locData.locationName}
          </Text>
        </Box>
      </Container>
      <Container p="1.225rem 0.625rem 0 0.625rem">
        <Card padding="0rem 0.75rem 0.75rem 0.75rem" radius="none">
          {/* start Martin's changes */}
          <Text size="lg" fw={700}>
            Location
          </Text>
          <Group style={{ gap: '0.1rem' }}>
            <Text size="xs">{locData.city},</Text>
            <Text size="xs">{locData.state} - </Text>
            <Text size="xs">{locData.zipCode}</Text>
          </Group>
          <Text size="lg" fw={700}>
            Email
          </Text>
          <Text
            size="xs"
            style={{
              color: theme.colors.brandBlue,
            }}
            component={Link}
            href={`mailto:${locData.email}`}
          >
            {locData.contactEmail}
          </Text>
          <Text size="lg" fw={700}>
            Phone
          </Text>
          <Text
            size="xs"
            component={Link}
            style={{
              color: theme.colors.brandBlue,
            }}
            href={`tel:${locData.contactPhone || locData.phoneNumber}`}
          >
            {locData.contactPhone || locData.phoneNumber}
          </Text>
          {/* end Martin's changes */}

          <Group
            noWrap
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {/* <Text size="xs">{locData.address} HEY</Text> */}
            <Spoiler
              maxHeight={200}
              showLabel="show more"
              hideLabel="hide"
              style={{ fontSize: '0.7rem' }}
            >
              <Text fz="sm" c="dimmed" mt="sm">
                {locData.description}
              </Text>
              <Text fz="sm">member since: {membershipDate}</Text>
            </Spoiler>
            {locData.verified ? (
              <Image
                src={badgeVerified.src}
                height={30}
                width={84}
                alt="verified badge"
              />
            ) : null}
            {isAdmin || isBizOwner ? (
              <ActionIcon
                radius="md"
                variant="filled"
                color="orange"
                onClick={() =>
                  router.push(`/admin/location/${cityUrl}/${nameUrl}/${locId}`)
                }
              >
                <FaPencilAlt />
              </ActionIcon>
            ) : null}
          </Group>

          {handleSubCats()}
        </Card>
      </Container>
      <Container mt="xl" mb="lg">
        <InfoMenuButtons
          locData={locData}
          locId={locId}
          contactModal={contactModal}
          hoursModal={hoursModal}
          mapModal={mapModal}
        />
        {dockUserId ? (
          <Group position="center" mt="lg" mb="lg">
            <Button
              fullWidth
              leftIcon={<FaEye size="1.2rem" />}
              variant="outline"
              color="cyan"
              component={Link}
              rel="noopener noreferrer"
              target="_blank"
              href={
                isLocal === 'development'
                  ? `http://localhost:3000/${userOrListing}/${dockUserId}`
                  : `https://thedock.boaterslist.com/${userOrListing}/${dockUserId}`
              }
            >
              Book A Trip
            </Button>
          </Group>
        ) : null}
      </Container>

      <Transition
        mounted={openedAdvert}
        transition="slide-up"
        duration={2000}
        timingFunction="ease"
      >
        {(styles) => (
          <Container style={styles} mt="xl">
            <Advertising adDetails={adData[position]} />
          </Container>
        )}
      </Transition>
    </>
  )
}
