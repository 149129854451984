import { formattedHours } from 'utils/FormattedHours'

export const getOperatingHours = (data: any) => {
  let hours
  if (data?.operatingDaysHoursJSON?.length) {
    if (data?.operatingDaysHoursJSON[0].callToCheck) {
      return (hours = data?.operatingDaysHoursJSON[0].callToCheck)
    } else {
      return (hours = formattedHours(data.operatingDaysHoursJSON))
    }
  } else return (hours = '')
}
