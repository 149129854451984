// pages/404.js

import {
  Group,
  Title,
  Container,
  Card,
  Button,
  Image,
  Text,
} from '@mantine/core'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

export default function Custom404() {
  const router = useRouter()
  return (
    <Container>
      <Group position="center" mt="4rem">
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          style={{ width: '400px' }}
        >
          <Card.Section>
            <Image
              src="https://www.datocms-assets.com/51196/1630442475-istock-497142968.jpg"
              height={300}
              alt="Ship At Sea"
            />
          </Card.Section>

          <Group mt="md" mb="xs">
            <Title weight={500}>Location Not Found</Title>
          </Group>

          <Text size="sm" color="dimmed">
            It appears the location you searched is not at this URL. Please try
            another search, or broaden your search area.
          </Text>

          <Button
            variant="light"
            color="blue"
            fullWidth
            mt="md"
            radius="md"
            component={Link}
            href="/"
          >
            Return to Home
          </Button>
        </Card>
      </Group>
    </Container>
  )
}
