import { getOperatingHours } from 'lib/getOperatingHours'
import { Text } from '@mantine/core'
import * as cat from 'common/global/categories'

export const makeContactModal = (locData: any) => {
  const contactModal = {
    label: 'Contact Info',
    'contact person': locData.contactPerson,
    email: locData.contactEmail,
    website: locData.website,
    'main phone': locData.contactPhone,
    'alt phone': locData.phoneNumber,
  }
  return contactModal
}

export const makeMapModal = (locData: any) => {
  const mapModal = {
    label: 'Location',
    address: locData.address1,
    city: locData.city,
    state: locData.state,
    zipCode: locData.zipCode,
    lat: locData.coordinate?.lat ? locData.coordinate.lat : null,
    lon: locData.coordinate?.lon ? locData.coordinate.lon : null,
  }
  return mapModal
}

export const makeHoursModal = (locData: any) => {
  const operatingHours = getOperatingHours(locData)
  const hoursModal = {
    label: 'Hours Of Operation',
    hours: operatingHours,
  }
  return hoursModal
}

export const makeServicesModal = (locData: any) => {
  let subCategoriesFilter = locData.subCategories
    ? [...locData.subCategories]
    : null

  const subcat: Array<string> = []
  subCategoriesFilter
    ?.slice(0, 5)
    .forEach((c) =>
      subcat.push(cat.getSubCategory(c)?.subCategoryName as string),
    )

  // return subcat.length > 0 ? (
  //   <>
  //     <Text>{subcat.join(', ')}</Text>
  //   </>
  // ) : null

  const servicesModal = {
    label: 'Services',
    'selected services':
      subcat.length > 0 ? <Text>{subcat.join(', ')}</Text> : '',
  }
  return servicesModal
}
