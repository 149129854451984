import React from 'react'

import {
  ActionIcon,
  Modal,
  Card,
  Text,
  Button,
  Group,
  Stack,
} from '@mantine/core'
import {
  FaPhoneAlt,
  FaEnvelopeOpen,
  FaRocket,
  FaPhoneVolume,
  FaClock,
  FaTools,
} from 'react-icons/fa'
import Link from 'next/link'

import { SearchResultsMap } from 'components/SearchResults/ResultsMap/SearchResultsMap'
import theme from 'common/theme'

interface MoreInfoProps {
  opened: boolean
  handleClose: () => void
  modalDetails: any
  fullData?: any
  locId?: any
  isMapModal?: boolean
}

export default function MoreInfoModals({
  opened,
  handleClose,
  modalDetails,
  fullData,
  locId,
  isMapModal,
}: MoreInfoProps) {
  const locationText =
    modalDetails.label === 'Location' ? (
      <>
        <Text size="lg" fw={700}>
          Location
        </Text>
        <Text size="xs">{modalDetails.address}</Text>
        <Group style={{ gap: '0.1rem' }}>
          <Text size="xs">{modalDetails.city},</Text>
          <Text size="xs">{modalDetails.state} - </Text>
          <Text size="xs">{modalDetails.zipCode}</Text>
        </Group>
      </>
    ) : null

  const handleLinkText = (type: string, details: string) => {
    if (type === 'email') {
      return (
        <Group style={{ alignItems: 'center' }} mt="xs">
          <ActionIcon radius="xl" variant="filled" size="xl" color="blue">
            <FaEnvelopeOpen size="1.5rem" />
          </ActionIcon>
          <Text
            size="sm"
            style={{
              color: theme.colors.brandBlue,
            }}
            component={Link}
            href={`mailto:${modalDetails.email}`}
          >
            {modalDetails[type]}
          </Text>
        </Group>
      )
    } else if (type === 'website') {
      return (
        <Group style={{ alignItems: 'flex-end' }} mt="xs">
          <ActionIcon radius="xl" variant="filled" size="xl" color="teal">
            <FaRocket size="1.5rem" />
          </ActionIcon>

          <Button
            compact
            color="teal"
            variant="outline"
            component={Link}
            href={fullData.website}
            rel="noopener noreferrer"
            target="_blank"
          >
            Visit Site
          </Button>
        </Group>
      )
    } else if (type.includes('phone')) {
      return type.includes('alt') ? (
        <Group style={{ alignItems: 'center' }} mt="xs">
          <ActionIcon radius="xl" variant="filled" size="xl" color="violet">
            <FaPhoneVolume size="1.8rem" />
          </ActionIcon>
          <Text>{details ? details : 'N/A'}</Text>
        </Group>
      ) : (
        <Group style={{ alignItems: 'center' }} mt="xs">
          <ActionIcon radius="xl" variant="filled" size="xl" color="yellow">
            <FaPhoneAlt size="1.6rem" />
          </ActionIcon>
          <Text>{details ? details : 'N/A'}</Text>
        </Group>
      )
    } else if (type === 'hours') {
      return (
        <Group style={{ alignItems: 'center' }} mt="xs">
          <ActionIcon radius="xl" variant="outline" size="xl" color="lime">
            <FaClock size="1.6rem" />
          </ActionIcon>
          <Text size="sm">{details}</Text>
        </Group>
      )
    } else if (type.includes('services')) {
      return (
        <Group style={{ alignItems: 'center' }} mt="xs">
          <ActionIcon radius="xl" variant="outline" size="xl" color="indigo">
            <FaTools size="1.6rem" />
          </ActionIcon>
          <Text size="sm">{details}</Text>
        </Group>
      )
    } else return <Text size="sm">{modalDetails[type]}</Text>
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        title={locationText ? locationText : modalDetails.label}
        transitionProps={{ transition: 'slide-left' }}
      >
        {modalDetails.lat && modalDetails.lon !== 0 ? (
          <div style={{ minHeight: '550px' }}>
            <SearchResultsMap
              showDirections
              locationsCoord={[{ source: fullData }] as any}
              id={locId}
              isMapModal={isMapModal}
            />
          </div>
        ) : null}

        {modalDetails.label !== 'Location' ? (
          <Card style={{ maxWidth: '100%' }}>
            <Stack align="flex-start">
              {Object.keys(modalDetails).map((key: any) => {
                if (key === 'label') return
                return (
                  <Card radius="md" p="xs" key={key}>
                    <Text size="xs" fw={500}>
                      {key.toUpperCase()} :
                    </Text>
                    <Group>{handleLinkText(key, modalDetails[key])}</Group>
                  </Card>
                )
              })}
            </Stack>
          </Card>
        ) : null}
      </Modal>
    </>
  )
}
