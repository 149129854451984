import React, { useEffect, useState } from 'react'

import { useMediaQuery } from '@mantine/hooks'
import { useUser } from '@auth0/nextjs-auth0'

import * as opensearch from 'web-app/lib/opensearch'
import * as cat from 'common/global/categories'
import hasRole from 'common/utils/hasRole'
import { request } from 'common/lib/datocms'
import { GetServerSideProps } from 'next'
import { getOperatingHours } from 'lib/getOperatingHours'

import Custom404 from '../404'
import SeoHead from 'components/LocationDetails/SeoHead'
import LocationPagePremiumMobile from '../../components/LocationDetails/LocationPagePremiumMobile'
import LocationPageMobile from 'components/LocationDetails/LocationPageMobile'
import LocationPageDesktop from 'components/LocationDetails/LocationPageDesktop'
import { Box, LoadingOverlay } from '@mantine/core'

const client = opensearch.newClient()

export const getServerSideProps: GetServerSideProps = async (context) => {
  const adData = await request({
    query: `query Homepage {
      allAds {
        id
        name
        url
        image1 {
          id
          responsiveImage {
            alt
            src
          }
        }
        image2 {
          id
          responsiveImage {
            alt
            src
          }
        }
      }

      allBannerAds {
        adImage {
          url
        }
        adTitle
        adText
        webLink
      }

    }`,
  })

  const locId = context?.query?.slug
    ? context.query.slug[context.query.slug.length - 1]
    : null

  if (!locId) {
    return {
      redirect: {
        permanent: false,
        destination: '/404',
      },
      props: {},
    }
  }

  let opensearchQuery
  opensearchQuery = {
    match: {
      _id: locId,
    },
  }

  const data = await opensearch.search(client, {
    index: 'locations',
    body: {
      size: 1,
      query: opensearchQuery,
    },
  })

  if (data?.body?.hits?.hits.length === 0) {
    return {
      redirect: {
        permanent: false,
        destination: '/404',
      },
      props: {},
    }
  }

  console.log('LOCATION', data.body?.hits?.hits)
  const loc = data.body?.hits?.hits[0]?._source
  const id = data.body?.hits?.hits[0]?._id
  const fullData = data.body?.hits?.hits[0]

  return {
    props: {
      data: loc,
      id: id,
      fullData: fullData,
      adData: adData,
    },
  }
}

export default function Location(props: any) {
  const { data, id, adData } = props
  const [dockUserId, setDockUserId] = useState('')
  const [userOrListing, setUserOrListing] = useState('')
  const [hasDockListings, setHasDockListings] = useState(false)
  const { user, isLoading } = useUser()
  const isAdmin = hasRole(user, 'admin')
  const isPremium = data.premiere
  const largeScreen = useMediaQuery('(min-width: 50em)')

  useEffect(() => {
    const getListings = async () => {
      const response = await fetch('/api/dock-fetch', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (response.status != 200) {
        console.log(`No Marketplace User Found - ${response.status} ${response.statusText}`)
        return
      }
      const jsonData = await response.json()
      //'l' for specific listing - 'u' routes to the biz 'store/profile' page on The Dock
      jsonData.isDirectListingLink
        ? setUserOrListing('l')
        : setUserOrListing('u')
      setHasDockListings(true)
      setDockUserId(jsonData.dockId)
    }
    getListings()
  }, [])

  if (isLoading) {
    return (
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
        loaderProps={{ size: 'xl', variant: 'dots' }}
      />
    )
  }

  if (!data) return <Custom404 />

  //get categories and subcategories by name and push into Array for SEO keywords
  const mainCategories = data?.categories?.length > 0 ? data.categories : 28
  const subCategories =
    data?.subCategories?.length > 0 ? data.subCategories : 500

  const seoKeywordList: Array<string> = []
  mainCategories.forEach((c: number) =>
    seoKeywordList.push(cat.getCategory(c)?.categoryName as string),
  )
  subCategories.forEach((c: number) =>
    seoKeywordList.push(cat.getSubCategory(c)?.subCategoryName as string),
  )

  const operatingHours = getOperatingHours(data)

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: data.locationName,
    description: data.description,
    keywords: seoKeywordList,
    openingHours: operatingHours,
    telephone: data.phoneNumber,
    email: data.contactEmail,
    branchOf: {
      '@type': 'Organization',
      name: data.locationName,
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: data.address1,
      addressLocality: data.city,
      addressRegion: data.state,
      postalCode: data.zipCode,
      addressCountry: data.country,
    },

    geo: data.coordinate
      ? {
          '@type': 'GeoCoordinates',
          latitude: data.coordinate.lat,
          longitude: data.coordinate.lon,
        }
      : null,
  }

  if (data?.active === true || (data?.active === false && isAdmin === true)) {
    return (
      <>
        <SeoHead data={data} jsonLd={jsonLd} />
        {isPremium ? (
          largeScreen ? (
            <LocationPageDesktop
              locData={data}
              locId={id}
              adData={adData.allAds}
              dockUserId={hasDockListings ? dockUserId : hasDockListings}
              userOrListing={userOrListing}
            />
          ) : (
            <LocationPagePremiumMobile
              locData={data}
              locId={id}
              dockUserId={hasDockListings ? dockUserId : hasDockListings}
              userOrListing={userOrListing}
            />
          )
        ) : largeScreen ? (
          <LocationPageDesktop
            locData={data}
            locId={id}
            adData={adData.allAds}
            dockUserId={hasDockListings ? dockUserId : hasDockListings}
            userOrListing={userOrListing}
          />
        ) : (
          <LocationPageMobile
            locData={data}
            locId={id}
            adData={adData.allAds}
            dockUserId={hasDockListings ? dockUserId : hasDockListings}
            userOrListing={userOrListing}
          />
        )}
      </>
    )
  } else return <Custom404 />
}
