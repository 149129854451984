import { getCategory } from 'common/global/categories'

export const locationNameUrlHelper = (name: string, city?: string) => {
  const nameUrl = name
    .replace(/[^a-zA-Z ]/g, '-')
    .replaceAll(' ', '-')
    .toLowerCase()
  const cityUrl = city
    ? city
        .replace(/[^a-zA-Z ]/g, '-')
        .replaceAll(' ', '-')
        .toLowerCase()
    : 'uknown-location'
  return { nameUrl: nameUrl, cityUrl: cityUrl }
}
// console.log(
//   locationNameUrlHelper(
//     "abc's test#s/my test-test&test@test%test",
//     'some/city',
//   ),
// )
export const categoryUrlHelper = (catNumber: number) => {
  const catName = getCategory(catNumber)
  const catUrl = catName?.categoryName
    ? catName?.categoryName
        .replaceAll(' ', '-')
        .replaceAll('/', '-')
        .toLowerCase()
    : 'various-services'
  return catUrl
}

export const nameOnlyUrlHelper = (name: string) => {
  const nameUrl = name.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase()

  return nameUrl
}

export const cityOnlyUrlHelper = (city: string) => {
  const cityUrl = city
    ? city.replaceAll(' ', '-').replaceAll('/', '').toLowerCase()
    : 'uknown-location'
  return cityUrl
}
