import React, { useState } from 'react'

import { useMediaQuery } from '@mantine/hooks'
import { getOperatingHours } from 'lib/getOperatingHours'

import { Grid, SimpleGrid, Button } from '@mantine/core'
import { FaPhoneAlt, FaMailBulk, FaPhoneSquare } from 'react-icons/fa'

import MoreInfoModals from './MoreInfoModals'

const infoArry = [
  {
    label: 'Contact',
    icon: <FaPhoneAlt size={14} style={{ color: 'white' }} />,
    menu: [
      {
        label: 'email',
        text: '',
        icon: <FaMailBulk size={15} style={{ color: 'black' }} />,
      },
      {
        label: 'main phone',
        text: '',
        icon: <FaPhoneAlt style={{ color: 'black' }} />,
      },
      {
        label: 'alt/mobile phone',
        text: '',
        icon: <FaPhoneSquare style={{ color: 'black' }} />,
      },
    ],
  },
  {
    label: 'Map',
    link: 'https://thedock.boaterslist.com',
    rel: 'noopener noreferrer',
    target: '_blank',
    icon: <FaPhoneAlt size="0.5rem" style={{ color: 'white' }} />,
  },
  {
    label: 'Services',
    text: '',
  },
  {
    label: 'Hours',
    link: 'https://thedock.boaterslist.com/u/65662e75-22fb-4a45-b3a8-e5f36c986fa1',
    rel: 'noopener noreferrer',
    target: '_blank',
    icon: <FaPhoneSquare size="0.5rem" style={{ color: 'white' }} />,
  },
]

export default function InfoMenuButtons(props: any) {
  const { locData, locId, contactModal, hoursModal, mapModal, servicesModal } =
    props

  const [opened, setOpened] = useState(false)
  const [modalDetails, setModalDetails] = useState({})
  const operatingHours = getOperatingHours(locData)
  const [isMapModal, setIsMapModal] = useState(false)
  const largeScreen = useMediaQuery('(min-width: 60em)')

  const handleClose = () => {
    setIsMapModal(false)
    setOpened(false)
  }

  const resolveModalDetails = (type: string) => {
    if (type === 'Contact') {
      setModalDetails(contactModal)
    } else if (type === 'Map') {
      setIsMapModal(true)
      setModalDetails(mapModal)
    } else if (type === 'Hours') {
      setModalDetails(hoursModal)
    } else setModalDetails(servicesModal)
  }

  const resolveLabelText = (labelString: string) => {
    if (labelString != 'email') {
      return locData.contactPhone
        ? locData.contactPhone
        : locData.phoneNumber
        ? locData.phoneNumber
        : ''
    } else if (labelString === 'email') {
      return locData.contactEmail
    }
  }
  return (
    <>
      <Grid gutter="xs" mt="md">
        <Grid.Col>
          <SimpleGrid cols={3}>
            {infoArry.map((section, indx) => {
              if (largeScreen && section.label === 'Map') return
              if (!largeScreen && section.label === 'Services') return
              return (
                <Button
                  key={indx}
                  fullWidth
                  variant="outline"
                  onClick={(e) => {
                    resolveModalDetails(section.label)
                    setOpened(true)
                  }}
                >
                  {section.label}
                </Button>
              )
            })}
          </SimpleGrid>
        </Grid.Col>
      </Grid>

      <MoreInfoModals
        opened={opened}
        handleClose={handleClose}
        modalDetails={modalDetails}
        fullData={locData}
        locId={locId}
        isMapModal={isMapModal}
      />
    </>
  )
}
