import React from 'react'
import Script from 'next/script'
import Head from 'next/head'

export default function SeoHead(props: any) {
  const { data, jsonLd } = props
  return (
    <>
      <Head>
        <title>{data.locationName}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content={data.description ? data.description : data.locationName}
        />
        <meta property="og:title" content={data.locationName} />
        <meta
          property="og:description"
          content={data.description ? data.description : data.locationName}
        />
        <meta
          property="og:image"
          content="https://www.datocms-assets.com/51196/1628362858-favicon.ico?auto=format&fit=max&w=1200"
        />
        <meta property="og:image:alt" content="boater icon" />
        <meta property="og:url" content={data.website ? data.website : ''} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.locationName} />
        <meta property="og:locale" content="en" />
        <meta name="twitter:title" content={data.locationName} />
        <meta
          name="twitter:description"
          content={data.description ? data.description : data.locationName}
        />
        <meta
          name="twitter:image"
          content="https://www.datocms-assets.com/51196/1628362858-favicon.ico?auto=format&fit=max&w=1200"
        />
        <meta name="twitter:image:alt" content="boater icon" />
        <meta name="twitter:card" content="summary" />
        <meta name="referrer" content="origin" />
      </Head>
      <Script
        id="add-location-schema "
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </>
  )
}
