import React, { useEffect, useState } from 'react'
import Link from 'next/link'

import {
  Image,
  Card,
  Text,
  Group,
  Button,
  rem,
  Paper,
  Spoiler,
  Stack,
  Container,
  SimpleGrid,
  Grid,
  Title,
  Transition,
  Center,
  Modal,
  ActionIcon,
} from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import { FaEye, FaMapPin, FaPencilAlt } from 'react-icons/fa'
import css from './LocationPages.module.css'
import badgePremium from 'common/assets/images/premium-badge.png'
import badgeVerified from 'common/assets/images/verified-badge.png'
import theme from 'common/theme'

import hasRole from 'common/utils/hasRole'
import { useUser } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'
import { picUrlHelper } from 'lib/picUrlHelper'
import { locationNameUrlHelper } from 'lib/urlHelpers'

import * as menuButtons from '../../lib/menuButtonsHelper'
import InfoMenuButtons from 'components/ContactInfo/InfoMenuButtons'
import { SearchResultsMap } from 'components/SearchResults/ResultsMap/SearchResultsMap'
import { Advertising } from 'components/Ads/Advertising'

const fallbackImage =
  'https://www.datocms-assets.com/51196/1630440888-istock-1225284622.jpg'

export default function LocationPageDesktop(props: any) {
  const { locData, locId, adData, dockUserId, userOrListing } = props

  const [images, setImages] = useState<string[]>([])
  const [openedModal, setOpenedModal] = useState(false)
  const [openedAdvert, setOpenedAdvert] = useState(false)
  const [position, setPosition] = useState(0)

  const { user } = useUser()
  const router = useRouter()
  const isBizOwner = user?.email === locData?.contactEmail
  const isAdmin = hasRole(user, 'admin')
  const isLocal = process.env.NODE_ENV
  const isPremium = locData.premiere
  const isVerified = locData.verified

  const imageTypes = ['pic1', 'pic2']
  const membershipDate = new Date(locData?.dateCreated).getFullYear()
  const PRIMARY_COL_HEIGHT = rem(660)

  const { nameUrl, cityUrl } =
    locData?.locationName && locData?.city
      ? locationNameUrlHelper(locData.locationName, locData.city)
      : locationNameUrlHelper('', '')

  const contactModal = menuButtons.makeContactModal(locData)
  const hoursModal = menuButtons.makeHoursModal(locData)
  const servicesModal = menuButtons.makeServicesModal(locData)

  useEffect(() => {
    const imgArr = imageTypes.map((type: string) => {
      return picUrlHelper(locId, type)
    })
    setImages(imgArr)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setOpenedAdvert(true)
    }, 800)

    let intervalId: any
    let adPosition = 0
    const adLooper = () => {
      setOpenedAdvert(false)
      adPosition = adPosition + 1

      if (adPosition === adData.length) {
        adPosition = 0
        setTimeout(() => {
          setPosition(adPosition)
          setOpenedAdvert(true)
        }, 3000)
      } else {
        setTimeout(() => {
          setPosition(adPosition)
          setOpenedAdvert(true)
        }, 3000)
      }
    }

    intervalId = setInterval(adLooper, 15000)
    return () => clearInterval(intervalId)
  }, [])

  const slides = images.map((image) => (
    <Carousel.Slide key={image}>
      <Image
        radius="md"
        src={image}
        alt="Images of Business Provider"
        withPlaceholder
        placeholder={
          <Image radius="md" src={fallbackImage} alt="Placeholder Image" />
        }
      />
    </Carousel.Slide>
  ))

  const handleClose = () => {
    setOpenedModal(false)
  }

  return (
    <>
      <>
        <Container mt="md" size="90.5rem">
          <div
            style={{
              backgroundColor: theme.colors.brandBlueDark,
            }}
          >
            <Group position="center">
              <Title order={2} c="white" p="0.325rem">
                {locData.locationName}
              </Title>
              {isAdmin || isBizOwner ? (
                <ActionIcon
                  radius="md"
                  variant="filled"
                  color="yellow"
                  onClick={() =>
                    router.push(
                      `/admin/location/${cityUrl}/${nameUrl}/${locId}`,
                    )
                  }
                >
                  <FaPencilAlt />
                </ActionIcon>
              ) : null}
            </Group>
          </div>

          <SimpleGrid cols={2} spacing="md">
            <SimpleGrid
              p="md"
              style={{
                minHeight: PRIMARY_COL_HEIGHT,
                border: '0.0625rem solid #dee2e6',
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
                alignItems: !isPremium ? 'start' : undefined,
              }}
            >
              {isPremium ? (
                <SimpleGrid cols={1} style={{ height: rem('380px') }}>
                  <Card
                    p="lg"
                    shadow="lg"
                    className={css.card}
                    radius="md"
                    style={{
                      position: 'relative',
                      height: rem('380px'),
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenedModal(true)
                    }}
                  >
                    <Image
                      radius="md"
                      src={picUrlHelper(locId, 'pic2')}
                      alt="Business Provider Image"
                      className={css.image}
                      withPlaceholder
                      height={rem('380px')}
                      placeholder={
                        <Image
                          radius="md"
                          height={rem('380px')}
                          src={fallbackImage}
                          alt="Placeholder Image"
                        />
                      }
                    />
                  </Card>
                </SimpleGrid>
              ) : null}

              <Stack style={{ justifyContent: 'space-between' }}>
                <Group
                  noWrap
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Spoiler
                    maxHeight={100}
                    showLabel="show more"
                    hideLabel="hide"
                    style={{ fontSize: '0.7rem' }}
                  >
                    <Text fz="sm" c="dimmed" mt="sm">
                      {locData.description}
                    </Text>
                    <Text fz="sm">member since: {membershipDate}</Text>
                  </Spoiler>

                  {isPremium || isVerified ? (
                    <Image
                      src={isPremium ? badgePremium.src : badgeVerified.src}
                      height={30}
                      width={84}
                      alt="verified badge"
                    />
                  ) : null}
                </Group>
                <Group>
                  <ActionIcon
                    color="teal"
                    variant="outline"
                    radius="xl"
                    size="1.8rem"
                  >
                    <FaMapPin size="1.1rem" />
                  </ActionIcon>
                  <Text fz="sm">
                    {locData.address1} - {locData.city}, {locData.state}
                  </Text>
                </Group>
                {/* start Martin's changes */}
                <Text size="lg" fw={700}>
                  Location
                </Text>
                <Group style={{ gap: '0.1rem' }}>
                  <Text size="xs">{locData.city},</Text>
                  <Text size="xs">{locData.state} - </Text>
                  <Text size="xs">{locData.zipCode}</Text>
                </Group>
                <Text size="lg" fw={700}>
                  Email
                </Text>
                <Text
                  size="xs"
                  style={{
                    color: theme.colors.brandBlue,
                  }}
                  component={Link}
                  href={`mailto:${locData.email}`}
                >
                  {locData.contactEmail}
                </Text>
                <Text size="lg" fw={700}>
                  Phone
                </Text>
                <Text
                  size="xs"
                  component={Link}
                  style={{
                    color: theme.colors.brandBlue,
                  }}
                  href={`tel:${locData.contactPhone || locData.phoneNumber}`}
                >
                  {locData.contactPhone || locData.phoneNumber}
                </Text>
                {/* end Martin's changes */}
                <InfoMenuButtons
                  contactModal={contactModal}
                  hoursModal={hoursModal}
                  servicesModal={servicesModal}
                  locData={locData}
                />
                {dockUserId ? (
                  <Group position="center" mt="lg" mb="lg">
                    <Button
                      fullWidth
                      leftIcon={<FaEye size="1.2rem" />}
                      variant="outline"
                      color="cyan"
                      component={Link}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={
                        isLocal === 'development'
                          ? `http://localhost:3000/${userOrListing}/${dockUserId}`
                          : `https://thedock.boaterslist.com/${userOrListing}/${dockUserId}`
                      }
                    >
                      Book A Trip
                    </Button>
                  </Group>
                ) : null}
              </Stack>
              {!isPremium ? (
                <>
                  <Transition
                    mounted={openedAdvert}
                    transition="pop"
                    duration={2000}
                    timingFunction="ease"
                  >
                    {(styles) => (
                      <Center style={styles}>
                        <Advertising adDetails={adData[position]} />
                      </Center>
                    )}
                  </Transition>
                </>
              ) : null}
            </SimpleGrid>

            <Grid gutter="md">
              <Grid.Col>
                <Paper
                  withBorder
                  pl="xl"
                  pr="xl"
                  style={{
                    height: PRIMARY_COL_HEIGHT,
                    position: 'relative',
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                  }}
                >
                  {locData.coordinate.lat && locData.coordinate.lon !== 0 ? (
                    <div
                      style={{
                        minHeight: '550px',
                        height: PRIMARY_COL_HEIGHT,
                      }}
                    >
                      <SearchResultsMap
                        showDirections
                        locationsCoord={[{ source: locData }] as any}
                        id={locId}
                      />
                    </div>
                  ) : null}
                </Paper>
              </Grid.Col>
            </Grid>
          </SimpleGrid>
        </Container>
        <Modal opened={openedModal} onClose={handleClose} title="Images">
          <Carousel withIndicators loop>
            {slides}
          </Carousel>
        </Modal>
      </>
    </>
  )
}
